import React, { useState, useEffect } from 'react';
import MediaControlCard from './PodcastCardMedia';

export default function ProcessedEpisodes() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("https://cr-podcast-upload-backend.onwuemene.com/processed_episodes")
        .then(res => res.json())
        .then((data) => {
            setData(data);
        }).catch(err => console.log(err));
    }, []);

    return (
        <div>
            <h1>Processed Episodes</h1>
            <ul>
                {data.map((episode) => (
                    <MediaControlCard podcastEpisode={episode}/>
                ))}
            </ul>
        </div>
    );
}