import { useEffect, useState } from 'react';
import './App.css';
import { DropzoneArea } from 'material-ui-dropzone';
import MediaControlCard from './components/PodcastCardMedia';
import ProcessedEpisodes from './components/ProcessedEpisodes';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function App() {
  const [fileObjects, setFileObjects] = useState([]);
  
  
  return (
    <div>
      {/* <p>{data}</p> */}

      <div className="dropzoneDiv">
        <DropzoneArea
          maxFileSize={500000000}
          fileObjects={fileObjects}
          onChange={(files) => {
            // console.log('Files:', files);
            
            //Now upload files to backend
            files.forEach(file => {
            const formData = new FormData();
            // console.log(file);
            formData.append('file', file, file.name);
            
            fetch("https://cr-podcast-upload-backend.onwuemene.com/upload", {
              method: "POST",
              body: formData
            }).then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((responseData) => {
              // Handle the API response as needed
              console.log(`File uploaded successfully: ${responseData.message}`);
            })
            .catch((error) => {
              // Handle any errors that occur during the upload
              console.log(`File upload failed: ${error}`);
            });
          })


          }}
          dropzoneText={"Drop Podcast File(s) Here"}
          dropzoneClass={"podcastDropzone"}
        /> 
      </div>
      

      <div className='processedFilesDiv'>
        <ProcessedEpisodes />
      </div>
    </div>
  );
}

export default App;
